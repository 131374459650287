@if(!isMinimized && !isPro){
<div class="border rounded mt-5 mb-5 px-5 pt-5 pb-2">
    <h1 class="text-success">Rentability Pro</h1>
    <p class="mb-5">Upgrade to list unlimited properties!</p>

    <div class="row">
        <div class="col-md-6">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Pay Monthly</h5>
                </div>
                <div class="card-body">
                    <p>Opting for the monthly payment plan with Rentability offers flexibility and convenience, allowing
                        you to manage your subscription on a month-to-month basis. This is an ideal choice for those who
                        prefer to evaluate services without a long-term commitment. With this plan, you can access all
                        the tools and features Rentability provides to streamline your rental management needs without
                        worrying about upfront costs. Adjusting your subscription or canceling it is also hassle-free,
                        making it a popular option for those who value short-term convenience.</p>
                    <button class="btn btn-outline-primary me-3 rounded-pill w-100 my-1" type="button"
                        (click)="onMonthly()">
                        <span class="px-5">$15.00/month*</span></button>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Pay Yearly</h5>
                </div>
                <div class="card-body">
                    <p>The yearly payment plan with Rentability is designed for those who want to maximize their savings
                        and enjoy uninterrupted service. By committing to an annual subscription, you effectively
                        receive two months free compared to the monthly plan, offering significant cost benefits over
                        time. This option is perfect for users who are confident in the value Rentability brings to
                        their rental management processes and are ready to invest in a full year of hassle-free service.
                        The yearly plan ensures peace of mind, as you won’t need to worry about monthly renewals or
                        potential rate increases.</p>
                    <button class="btn bg-bright-gradient rounded-pill fw-bold w-100" type="button"
                        (click)="onYearly()">
                        <span class="px-3">$150.00/year*</span></button>
                </div>
            </div>
        </div>


    </div>
    <div class="col-md-12">
        <div class="mt-5 form-floating ms-auto w-25">
            <input id="referralCode" type="text" class="form-control" [(ngModel)]="referralCode" />
            <label for="referralCode" class="form-label">Referral Code</label>
        </div>
        <div class="mt-4">
            <p class="text-end opacity-75">* Subscriptions automatically renew</p>
        </div>
    </div>
</div>

}

@if(!isPro && isMinimized){
<div class="bg-dark-gradient text-light rounded m-1 p-3">
    <h5 class="text-success">Rentability Pro</h5>
    <a class="mb-5 text-light" [routerLink]="['/pricing']" href="/pricing">Learn about the benefits of upgrading to a
        Pro account</a>
</div>
}

@if(isPro && !isExpiring){
<div class="bg-dark-gradient text-light rounded px-2 py-2">
    <strong class="text-success">Rentability Pro</strong>
    <p class="">You are enjoying all the benefits of our pro account.</p>
    <a class="btn btn-outline-primary ms-auto" type="button" (click)="onManageSubscription()">Manage Subscription</a>
</div>
}

@if(isPro && isExpiring){
<div class="bg-dark-gradient text-light roundedpx-5 pt-5 pb-2">
    <strong class="text-success">Rentability Pro</strong>
    <p class="mb-5">You are enjoying all the benefits of our pro account. Your pro subscription expires on: {{expiryDate
        | date}}</p>
</div>
}